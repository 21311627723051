.container {
  max-height: calc(100vh - 250px);
}

.table_header {
  font-weight: 600;
  font-size: 1rem;
  white-space: nowrap;
}

.cell_text {
  margin: auto;
}

.header {
  font-size: 1.3rem;
  span {
    display: block;
  }
  span:first-child {
    text-transform: uppercase;
  }
  span:last-child {
    font-size: 0.9rem;
  }
}

button.menu_button {
  margin: 0 5px;
  font-size: .8rem;
  color: #ffffff;

  &:hover {
    color: yellow;
  }
}

.nav {
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
  flex-grow: 1;
}

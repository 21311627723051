.url_wrapper {
  text-align: right;
}

.link {
  display: inline-block;
  text-align: right;
  padding: 3px 9px;
  margin-left: 10px;
  margin-bottom: 10px;
  border: 1px solid;
  border-radius: 4px;
  color: #444444;
  &:hover {
    color: #2e7d32;
  }
}

.list_item {
  margin:  0 auto;
  align-items: flex-start;
  &:nth-child(odd) {
    background: rgba(black, 0.06);
  }
}

.item_name {
  margin-right: 30px;
  margin-top: 5px;
  font-weight: bold;
  color: darkslategrey;
}

.item_text {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

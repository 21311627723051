.header {
  text-align: center;
  font-size: 1.2rem;
  text-transform: uppercase;
}

form .input {
  display: block;
  margin: 20px 0;
}

.form {
  padding: 20px 50px;
}

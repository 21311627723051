.list_item {
  margin:  0 auto;
  align-items: flex-start;
  &:nth-child(odd) {
    background: rgba(black, 0.06);
  }
}

.item_name {
  margin-right: 30px;
  font-weight: bold;
  color: darkslategrey;
}

.item_text > span {
  display: flex;
  justify-content: space-between;
}
